import { helper } from '~public/js/src/salon/business/default/services/helper.js'
import { debounce } from '~src/utils.ts'

export function onClickInputNumberSelect(selectors: string[]) {
  selectors.forEach((selector) => {
    const inputs = document.querySelectorAll(selector)
    inputs.forEach((input) => {
      input.addEventListener('click', (event) => {
        const target = event.target as HTMLInputElement
        if (target.value === '0,00') {
          target.select()
        }
      })
    })
  })
}

let removeContainerFadeListeners: () => void = () => {}
export function showFadeOnTable(): () => void {
  removeContainerFadeListeners()

  function toggleContainerScrollFade(container: HTMLElement): void {
    if (!container || !container.classList.contains('table-responsive')) {
      return
    }

    const isAtEnd = Math.round(container.scrollLeft) >= Math.round(container.scrollWidth - container.clientWidth)

    container.classList.toggle('show-fade', !isAtEnd)
  }

  const resizeHandler = debounce(() => {
    const tables: NodeListOf<HTMLElement> = document.querySelectorAll('.table-responsive')
    tables.forEach(toggleContainerScrollFade)
  }, 30)

  window.addEventListener('resize', resizeHandler)

  const tables: NodeListOf<HTMLElement> = document.querySelectorAll('.table-responsive')

  const scrollHandler = (event: Event) => toggleContainerScrollFade(event.target as HTMLElement)

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      const affectedContainer = (mutation.target as HTMLElement).closest('.table-responsive') as HTMLElement
      if (affectedContainer) {
        toggleContainerScrollFade(affectedContainer)
      }
    })
  })

  tables.forEach((table) => {
    table.addEventListener('scroll', scrollHandler)
    toggleContainerScrollFade(table)
    observer.observe(table, { childList: true, subtree: true })
  })

  removeContainerFadeListeners = (): void => {
    tables.forEach((table) => {
      table.removeEventListener('scroll', scrollHandler)
      observer.disconnect()
    })
    window.removeEventListener('resize', resizeHandler)
  }

  return removeContainerFadeListeners
}

export async function clampInputDateRange(
  selector: string,
  months: number = 12,
  options: { from: string; to: string; datepickerOptions?: object } = {
    from: '[name=from]',
    to: '[name=to]',
    datepickerOptions: {},
  },
): Promise<void> {
  const element = $(selector)
  element.datepicker({
    language: 'std',
    format: 'dd.mm.yyyy',
    autoclose: true,
    endDate: '+0d',
    ...(options.datepickerOptions ?? {}),
  })

  const inputStart = element.find(options.from)
  const inputEnd = element.find(options.to)

  inputStart.on('changeDate', (e: Event) => {
    e.preventDefault()
    helper.dateRange(inputStart, inputEnd, months)
  })
}
